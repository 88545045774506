<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li>
            <input type="text" class="form-control" v-model="minPrice" readonly />
          </li>
          <li><span>to</span></li>
          <li>
            <input type="text" class="form-control" v-model="price" readonly />
          </li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#requirement-filter" role="button" aria-expanded="true"
          aria-controls="requirement-filter">
          Requirements
        </h6>
        <div class="wrapper collapse show" id="requirement-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="everydayRequirement" id="requirement-0"
                  @change="everydayRequirementChanged" />
                <label for="requirement-0">For every-day requirements</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="zooTurboNozzle" id="requirement-1" @change="zooTurboNozzleChanged" />
                <label for="requirement-1">Zoo'o turbo nozzle</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="proSilence" id="requirement-2" @change="proSilenceChanged" />
                <label for="requirement-2">ProSilence/very silent noise level</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="turboNozzle" id="requirement-3" @change="turboNozzleChanged" />
                <label for="requirement-3">Turbo nozzle</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#colour-filter" role="button" aria-expanded="true"
          aria-controls="colour-filter">
          Colour
        </h6>
        <div class="wrapper collapse show" id="colour-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#construction-filter" role="button" aria-expanded="true"
          aria-controls="construction-filter">
          Construction Type
        </h6>
        <div class="wrapper collapse show" id="construction-filter">
          <VueMultiselect v-model="constructionType" :options="constructionTypes" :multiple="true"
            :close-on-select="true" open-direction="bottom" placeholder="Select construction types" :searchable="false"
            selectLabel="" deselectLabel="" @input="constructionTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#filtration-filter" role="button" aria-expanded="true"
          aria-controls="filtration-filter">
          Filtration System
        </h6>
        <div class="wrapper collapse show" id="filtration-filter">
          <VueMultiselect v-model="filtration" :options="filtrations" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select filtrations" :searchable="false" selectLabel="" deselectLabel=""
            @input="filtrationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#charging-filter" role="button" aria-expanded="true"
          aria-controls="charging-filter">
          Charging Time
        </h6>
        <div class="wrapper collapse show" id="charging-filter">
          <VueMultiselect v-model="chargingTime" :options="chargingTimes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select charging times" :searchable="false" selectLabel=""
            deselectLabel="" @input="chargingTimeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#accessory-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Included accessories
        </h6>
        <div class="wrapper collapse show" id="accessory-filter">
          <VueMultiselect v-model="accessory" :options="accessories" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select accessories" :searchable="false" selectLabel="" deselectLabel=""
            @input="accessoryChanged" />

        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key Features
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="handheld" id="feature-0" @change="handheldChanged" />
                <label for="feature-0">2 in 1: handheld and handstick in one appliance</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="electronicNozzle" id="feature-1" @change="electronicNozzleChanged" />
                <label for="feature-1">Electronic nozzle</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#type-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Type
        </h6>
        <div class="wrapper collapse show" id="type-filter">
          <VueMultiselect v-model="type" :options="types" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select types" :searchable="false" selectLabel="" deselectLabel=""
            @input="typeChanged" />

        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#runtime-filter" role="button" aria-expanded="true"
          aria-controls="runtime-filter">
          Maximum Runtime
        </h6>
        <div class="wrapper collapse show" id="runtime-filter">
          <VueMultiselect v-model="runtime" :options="runtimes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select runtimes" :searchable="false" selectLabel="" deselectLabel=""
            @input="runtimeChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#series-filter" role="button" aria-expanded="true"
          aria-controls="series-filter">
          Product series
        </h6>
        <div class="wrapper collapse show" id="series-filter">
          <VueMultiselect v-model="series" :options="serieses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select serieses" :searchable="false" selectLabel="" deselectLabel=""
            @input="seriesChanged" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "CordlessVacuumCleanerFilters",
  data: function () {
    return {
      types: [],
      runtimes: [],
      serieses: [],
      heights: [],
      widths: [],
      depths: [],
      colors: [],
      constructionTypes: [],
      chargingTimes: [],
      filtrations: [],
      accessories: [],
      minPrice: 0,
      maxPrice: 0,

      price: 0,
      type: [],
      runtime: [],
      series: [],
      height: [],
      width: [],
      depth: [],
      color: [],
      constructionType: [],
      chargingTime: [],
      filtration: [],
      accessory: [],
      everydayRequirement: false,
      zooTurboNozzle: false,
      proSilence: false,
      turboNozzle: false,
      handheld: false,
      electronicNozzle: false,
    };
  },
  components: {
    VueMultiselect,
  },

  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    },
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_rechargeable_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.types = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_laufzeit_max",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.runtimes = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter((item) => !["", null].includes(item));
      });

      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_constr_type_rechargeable",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.constructionTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_charging_time",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.chargingTimes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_filtration_system",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.filtrations = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_accessories_incl",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.accessories = response.filter(
          (item) => !["", null].includes(item)
        );
      });
    },

    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    typeChanged() {
      let filters = {
        attr_rechargeable_type__in: _.map(this.type, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    constructionTypeChanged() {
      let filters = {
        attr_constr_type_rechargeable__in: _.map(
          this.constructionType,
          (item) => {
            return '"' + item + '"';
          }
        ).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    chargingTimeChanged() {
      let filters = {
        attr_charging_time__in: _.map(this.chargingTime, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    filtrationChanged() {
      let filters = {
        attr_filtration_system__in: _.map(this.filtration, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    accessoryChanged() {
      let filters = {
        attr_accessories_incl__in: _.map(this.accessory, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    runtimeChanged() {
      let filters = {
        attr_laufzeit_max__in: _.map(this.runtime, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    everydayRequirementChanged() {
      let filters = {
        attr_classic_concept_vacl: this.everydayRequirement ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    zooTurboNozzleChanged() {
      let filters = {
        attr_zoo_turbo_nozzle: this.zooTurboNozzle ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    proSilenceChanged() {
      let filters = {
        attr_pro_silence: this.proSilence ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    turboNozzleChanged() {
      let filters = {
        attr_turbo_nozzle: this.turboNozzle ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    handheldChanged() {
      let filters = {
        attr_2in1_vacl: this.handheld ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    electronicNozzleChanged() {
      let filters = {
        attr_electronic_nozzle: this.electronicNozzle ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    existingFilters() {
      this.everydayRequirement = this.selectedFilters.attr_classic_concept_vacl;
      this.zooTurboNozzle = this.selectedFilters.attr_zoo_turbo_nozzle;
      this.proSilence = this.selectedFilters.attr_pro_silence;
      this.turboNozzle = this.selectedFilters.attr_turbo_nozzle;
      this.handheld = this.selectedFilters.attr_2in1_vacl;
      this.electronicNozzle = this.selectedFilters.attr_electronic_nozzle;

      if (this.selectedFilters.attr_laufzeit_max__in) {
        let sortedRuntimes = [];
        let unsortedRuntimes = this.selectedFilters.attr_laufzeit_max__in.split(
          '","'
        );
        unsortedRuntimes.map((runtime) => {
          runtime = runtime.replaceAll('"', "");
          sortedRuntimes.push(runtime);
        });
        this.runtime = sortedRuntimes;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', "");
          sortedSeries.push(series);
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_rechargeable_type__in) {
        let sortedTypes = [];
        let unsortedTypes = this.selectedFilters.attr_rechargeable_type__in.split(
          '","'
        );
        unsortedTypes.map((type) => {
          type = type.replaceAll('"', "");
          sortedTypes.push(type);
        });
        this.type = sortedTypes;
      }

      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', "");
          sortedWidths.push(width);
        });
        this.width = sortedWidths;
      }

      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }

      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_main__in.split(
          '","'
        );
        unsortedColors.map((color) => {
          color = color.replaceAll('"', "");
          sortedColors.push(color);
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.attr_constr_type_rechargeable__in) {
        let sortedConstructions = [];
        let unsortedConstructions = this.selectedFilters.attr_constr_type_rechargeable__in.split(
          '","'
        );
        unsortedConstructions.map((construction) => {
          construction = construction.replaceAll('"', "");
          sortedConstructions.push(construction);
        });
        this.constructionType = sortedConstructions;
      }

      if (this.selectedFilters.attr_charging_time__in) {
        let sortedTimes = [];
        let unsortedTimes = this.selectedFilters.attr_charging_time__in.split(
          '","'
        );
        unsortedTimes.map((time) => {
          time = time.replaceAll('"', "");
          sortedTimes.push(time);
        });
        this.chargingTime = sortedTimes;
      }

      if (this.selectedFilters.attr_filtration_system__in) {
        let sortedFiltrations = [];
        let unsortedFiltrations = this.selectedFilters.attr_filtration_system__in.split(
          '","'
        );
        unsortedFiltrations.map((filtration) => {
          filtration = filtration.replaceAll('"', "");
          sortedFiltrations.push(filtration);
        });
        this.filtration = sortedFiltrations;
      }

      if (this.selectedFilters.attr_accessories_incl__in) {
        let sortedAccessories = [];
        let unsortedAccessories = this.selectedFilters.attr_accessories_incl__in.split(
          '","'
        );
        unsortedAccessories.map((accessory) => {
          accessory = accessory.replaceAll('"', "");
          sortedAccessories.push(accessory);
        });
        this.accessory = sortedAccessories;
      }
    },
  },
};
</script>

<style scoped></style>
