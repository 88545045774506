var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#requirement-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "requirement-filter"
            }
          },
          [_vm._v(" Requirements ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "requirement-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.everydayRequirement,
                        expression: "everydayRequirement"
                      }
                    ],
                    attrs: { type: "checkbox", id: "requirement-0" },
                    domProps: {
                      checked: Array.isArray(_vm.everydayRequirement)
                        ? _vm._i(_vm.everydayRequirement, null) > -1
                        : _vm.everydayRequirement
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.everydayRequirement,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.everydayRequirement = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.everydayRequirement = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.everydayRequirement = $$c
                          }
                        },
                        _vm.everydayRequirementChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "requirement-0" } }, [
                    _vm._v("For every-day requirements")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.zooTurboNozzle,
                        expression: "zooTurboNozzle"
                      }
                    ],
                    attrs: { type: "checkbox", id: "requirement-1" },
                    domProps: {
                      checked: Array.isArray(_vm.zooTurboNozzle)
                        ? _vm._i(_vm.zooTurboNozzle, null) > -1
                        : _vm.zooTurboNozzle
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.zooTurboNozzle,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.zooTurboNozzle = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.zooTurboNozzle = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.zooTurboNozzle = $$c
                          }
                        },
                        _vm.zooTurboNozzleChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "requirement-1" } }, [
                    _vm._v("Zoo'o turbo nozzle")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.proSilence,
                        expression: "proSilence"
                      }
                    ],
                    attrs: { type: "checkbox", id: "requirement-2" },
                    domProps: {
                      checked: Array.isArray(_vm.proSilence)
                        ? _vm._i(_vm.proSilence, null) > -1
                        : _vm.proSilence
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.proSilence,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.proSilence = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.proSilence = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.proSilence = $$c
                          }
                        },
                        _vm.proSilenceChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "requirement-2" } }, [
                    _vm._v("ProSilence/very silent noise level")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.turboNozzle,
                        expression: "turboNozzle"
                      }
                    ],
                    attrs: { type: "checkbox", id: "requirement-3" },
                    domProps: {
                      checked: Array.isArray(_vm.turboNozzle)
                        ? _vm._i(_vm.turboNozzle, null) > -1
                        : _vm.turboNozzle
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.turboNozzle,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.turboNozzle = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.turboNozzle = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.turboNozzle = $$c
                          }
                        },
                        _vm.turboNozzleChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "requirement-3" } }, [
                    _vm._v("Turbo nozzle")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#colour-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "colour-filter"
            }
          },
          [_vm._v(" Colour ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "colour-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#construction-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "construction-filter"
            }
          },
          [_vm._v(" Construction Type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "construction-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.constructionTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select construction types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.constructionTypeChanged },
              model: {
                value: _vm.constructionType,
                callback: function($$v) {
                  _vm.constructionType = $$v
                },
                expression: "constructionType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#filtration-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "filtration-filter"
            }
          },
          [_vm._v(" Filtration System ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "filtration-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.filtrations,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select filtrations",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.filtrationChanged },
              model: {
                value: _vm.filtration,
                callback: function($$v) {
                  _vm.filtration = $$v
                },
                expression: "filtration"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#charging-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "charging-filter"
            }
          },
          [_vm._v(" Charging Time ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "charging-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.chargingTimes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select charging times",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.chargingTimeChanged },
              model: {
                value: _vm.chargingTime,
                callback: function($$v) {
                  _vm.chargingTime = $$v
                },
                expression: "chargingTime"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#accessory-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Included accessories ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "accessory-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.accessories,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select accessories",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.accessoryChanged },
              model: {
                value: _vm.accessory,
                callback: function($$v) {
                  _vm.accessory = $$v
                },
                expression: "accessory"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Key Features ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.handheld,
                        expression: "handheld"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-0" },
                    domProps: {
                      checked: Array.isArray(_vm.handheld)
                        ? _vm._i(_vm.handheld, null) > -1
                        : _vm.handheld
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.handheld,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.handheld = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.handheld = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.handheld = $$c
                          }
                        },
                        _vm.handheldChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-0" } }, [
                    _vm._v("2 in 1: handheld and handstick in one appliance")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.electronicNozzle,
                        expression: "electronicNozzle"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-1" },
                    domProps: {
                      checked: Array.isArray(_vm.electronicNozzle)
                        ? _vm._i(_vm.electronicNozzle, null) > -1
                        : _vm.electronicNozzle
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.electronicNozzle,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.electronicNozzle = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.electronicNozzle = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.electronicNozzle = $$c
                          }
                        },
                        _vm.electronicNozzleChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-1" } }, [
                    _vm._v("Electronic nozzle")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#type-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "type-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.types,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.typeChanged },
              model: {
                value: _vm.type,
                callback: function($$v) {
                  _vm.type = $$v
                },
                expression: "type"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#runtime-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "runtime-filter"
            }
          },
          [_vm._v(" Maximum Runtime ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "runtime-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.runtimes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select runtimes",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.runtimeChanged },
              model: {
                value: _vm.runtime,
                callback: function($$v) {
                  _vm.runtime = $$v
                },
                expression: "runtime"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#series-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "series-filter"
            }
          },
          [_vm._v(" Product series ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "series-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.serieses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select serieses",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.seriesChanged },
              model: {
                value: _vm.series,
                callback: function($$v) {
                  _vm.series = $$v
                },
                expression: "series"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }